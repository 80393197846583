/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { FileTemplateHEIC } from '../../../../../../common/images';

import { useUpdatePhotoProductMutate } from '../../../../../../api/file/hooks/useUpdatePhotoProductMutate';

import { Skeleton } from '../../../../../../componentUI/complex/Skeleton';
import { BrandedButton } from '../../../../../../componentUI/simple/Button';

import { TrashIcon } from '../../../../../../common/icons/SvgInline/SvgIcons_2';

import { inputPhotoClear } from '../../../../../../helpers';

import { handleDeleteImage } from '../../../../helpers';
import { UploadPhotosIndexType } from '../../../../types';

import { ContainerPictureStyled, stylesForButtons, stylesForImg, WrapperSkeletonStyled } from './styled';
import { Props } from './types';

export const UploadingNewRequestedPhoto = ({
  inputRef,
  angleId,
  productId,
  files,
  setFiles,
  setAllUploadedPhotos,
  setIndexUploadedPhotos,
  setIsErrorLimitUploadedImages,
  setIsLoadingUploadedPhotos,
}: Props) => {
  // количество загруженных фотографий на бэк в текущем индексе
  const [uploadPhotos, setUploadPhotos] = useState<Array<UploadPhotosIndexType>>([]);

  const {
    mutate: updatePhoto,
    data: dataUpdatePhoto,
    isLoading: isLoadingUpdatePhoto,
    isSuccess: isSuccessUpdatePhoto,
    isError: isErrorUploadingPhoto,
  } = useUpdatePhotoProductMutate();

  useEffect(() => {
    if (files.length > 0) {
      const idempotencyKey = crypto.randomUUID();
      updatePhoto({ productFileInfo: { angleId, productId, photos: files }, idempotencyKey });
    }
  }, [files]);

  // если выбранные файлы загрузились в индекс то отчищаем массив с
  // ранее выбранными файлами пользователем (files) и добавляем их
  // к общим загруженным файлам для заказа
  useEffect(() => {
    if (dataUpdatePhoto) {
      const photoId: Array<string> = [];

      const photos = dataUpdatePhoto.map(({ id, url }) => {
        photoId.push(id);
        return { path: url, fileId: id, angleId, isUnableProvide: false };
      });

      setAllUploadedPhotos((prev) => [...prev, ...photos]);

      setUploadPhotos((prev) => [...prev, ...photos]);

      setIndexUploadedPhotos((prev) => [...prev, ...photoId]);

      setFiles([]);
    }
  }, [isSuccessUpdatePhoto]);

  useEffect(() => {
    setIsLoadingUploadedPhotos(isLoadingUpdatePhoto);
  }, [isLoadingUpdatePhoto]);

  // при возникновении ошибки удаляем массив с выбранными пользователем файлами
  // и сбрасываем состояние поля input
  useEffect(() => {
    if (isErrorUploadingPhoto) {
      inputPhotoClear(inputRef);
      setFiles([]);
    }
  }, [isErrorUploadingPhoto]);

  return (
    <>
      {uploadPhotos.length > 0 &&
        uploadPhotos.map((photo, index) => {
          const fileExtension = photo.path.split('.').pop();

          return (
            <ContainerPictureStyled key={index}>
              <BrandedButton
                style={stylesForButtons}
                onClick={() =>
                  handleDeleteImage({
                    setIndexUploadedPhotos,
                    setAllUploadedPhotos,
                    setUploadPhotos,
                    setIsErrorLimitUploadedImages,
                    inputRef,
                    fileId: photo.fileId || '',
                  })
                }
              >
                <TrashIcon />
              </BrandedButton>

              {fileExtension && fileExtension.toLowerCase() !== 'heic' && (
                <img style={stylesForImg} src={photo.path} alt="loaded" />
              )}

              {fileExtension && fileExtension.toLowerCase() === 'heic' && (
                <img style={stylesForImg} src={FileTemplateHEIC} alt="loaded" />
              )}
            </ContainerPictureStyled>
          );
        })}

      {isLoadingUpdatePhoto &&
        files.map((_, index) => {
          return (
            <WrapperSkeletonStyled key={index}>
              <Skeleton variant="rounded" height="100%" width="100%" />
            </WrapperSkeletonStyled>
          );
        })}
    </>
  );
};
