import React from 'react';
import styled, { CSSProperties } from 'styled-components';
import { COLOR_MAP } from '../componentUI/colorsMap';
import { Text, TEXT_SIZE } from '../componentUI/simple/Text';
import { CanceledIcon, FakeIcon, LegitIcon, SkipOutcomeIcon } from '../common/icons/SvgInline/SvgIcons_1';
import { CheckStatus } from '../api/product/type';

export const OutcomeInfoStyled = styled.div<{ isCard: boolean }>`
  display: flex;
  gap: ${({ isCard }) => (isCard ? '8px' : '4px')};
  flex-direction: ${({ isCard }) => (isCard ? 'row-reverse' : 'row')};
  align-items: center;
  width: 100%;
  justify-content: ${({ isCard }) => (isCard ? 'flex-end' : 'flex-start')};
`;

const stylesForText: CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const OUTCOME_ICON_MAP: Record<CheckStatus, JSX.Element> = {
  LEGIT: <LegitIcon color={COLOR_MAP.accent.green} />,
  FAKE: <FakeIcon />,
  CANCELED: <CanceledIcon />,
  DRAFT: <SkipOutcomeIcon />,
};

const OUTCOME_TEXT_MAP: Record<CheckStatus, string> = {
  LEGIT: 'Authentic',
  FAKE: 'Fake',
  CANCELED: 'Unable to verify',
  DRAFT: 'Draft',
};

/**
 * получение информации для поля Outcome заказа пользователя,
 * формируется пара цвет/текст исходя из полученного CheckStatus заказа
 *
 * isCard - флаг который меняет оформление на то которое используется в
 * карточке заказа
 * isBigSize - флаг, выставляет размер шрифта равным TEXT_SIZE.regular
 * isDraft - флаг который указывает что данный заказ находится в статусе DRAFT
 * данный статус не должен иметь визуального отображения Outcome в списке заказов пользователя
 * когда список представлен в виде отдельных карточек
 */
export const getOutcomeInfo = (checkStatus: CheckStatus | null, isCard = false, isBigSize = false, isDraft = false) => {
  // на текущий момент у драфта нет своего визуального отображения в столбце Outcome
  if (isDraft) {
    return null;
  }

  if (checkStatus) {
    return (
      <OutcomeInfoStyled isCard={isCard}>
        {OUTCOME_ICON_MAP[checkStatus]}

        <Text style={stylesForText} size={isCard || isBigSize ? TEXT_SIZE.regular : TEXT_SIZE.sub}>
          {OUTCOME_TEXT_MAP[checkStatus]}
        </Text>
      </OutcomeInfoStyled>
    );
  }

  return (
    <OutcomeInfoStyled isCard={isCard}>
      <IconWrapper>
        <SkipOutcomeIcon />
        <Text colorText={COLOR_MAP.text.white}>-</Text>
      </IconWrapper>
    </OutcomeInfoStyled>
  );
};
