import { createAction, createReducer } from '@reduxjs/toolkit';
import { NotificationType } from '../../componentUI/types';

type ToastItem = {
  id: string;
  text?: string | Array<string>;
  type?: NotificationType;
};

export const addToast = createAction<{ text?: string | Array<string>; type?: NotificationType }>('ADD_TOAST');
export const deleteToast = createAction<{ id: string }>('DELETE_TOAST');

const initialState: { toasts: Array<ToastItem> } = {
  toasts: [],
};

export const toastReducer = createReducer(initialState, (builder) => {
  builder
    .addCase('ADD_TOAST', (state, action) => {
      let newText = '';

      if (Array.isArray(action.payload.text)) {
        newText = action.payload.text.map((element: string) => `${element}\n`).join(' ');
      } else {
        newText = action.payload.text || 'Server error. Please try again.';
      }

      // Check if the toast with the same text already exists
      const existingToastIndex = state.toasts.findIndex((toast) => toast.text === newText);
      if (existingToastIndex !== -1) {
        // If it exists, remove it
        state.toasts.splice(existingToastIndex, 1);
      }

      state.toasts.push({
        id: crypto.randomUUID(),
        text: newText,
        type: action.payload.type,
      });
    })
    .addCase('DELETE_TOAST', (state, action) => {
      state.toasts = state.toasts.filter((toast) => toast.id !== action.payload.id);
    });
});
