/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination } from '../../../components/Pagination';
import { useLocalStorage, useMatchMedia } from '../../../hooks';
import { useUserTransactionsCreditsQuery } from '../../../api/userCredits/hooks';
import { ArrowDownCircleIcon, ArrowUpCircleIcon } from '../../../common/icons/SvgInline/SvgIcons_10';
import { NoTransactionsIcon } from '../../../common/images';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { Text, TEXT_SIZE, TEXT_WEIGHT } from '../../../componentUI/simple/Text';
import { Title, TITLE_SIZE } from '../../../componentUI/simple/Title';
import { dataFormatterViewFive } from '../../../helpers/dataFormatter';
import { TransactionsSkeleton } from '../skeleton';
import {
  ArrivalAndExpenseStyled,
  TabletElementStyled,
  TransactionInformationStyled,
  TransactionsStyled,
  WrapperBalanceAfterStyled,
  WrapperDateAndTimeStyled,
  WrapperDescriptionsElementStyled,
  WrapperNoTransactionsStyled,
  WrapperPaginationStyled,
  WrapperTabledTransactionsStyled,
} from './styled';
import { Props } from './types';

const BOUNDARY_DETERMINING_BALANCE = 0;

export const Transactions = ({ recordLimitTransactions }: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();
  const [limit, setLimit] = useLocalStorage('limit_pagination', { key: '10', value: '10', label: '10' });

  // Contains the current page number selected in the Pagination block
  const [pageNumber, setPageNumber] = useState(1);

  const {
    data: UserTransactionsCreditsData,
    isLoading: UserTransactionsCreditsIsLoading,
    isSuccess: UserTransactionsCreditsIsSuccess,
    isError: UserTransactionsCreditsIsError,
  } = useUserTransactionsCreditsQuery({
    limit: Number(limit.value),
    page: pageNumber,
    sort: [['createdAt', 'DESC']],
  });

  const isNoTransactions = UserTransactionsCreditsIsSuccess && UserTransactionsCreditsData.entities.length === 0;

  if (!UserTransactionsCreditsData || UserTransactionsCreditsIsError) {
    return null;
  }

  return (
    <TransactionsStyled recordLimitTransactions={Boolean(recordLimitTransactions?.value)}>
      {!recordLimitTransactions && (
        <Title size={TITLE_SIZE.h1}>{t('balancePage.recentTransactions.transactions.title')}</Title>
      )}

      {isNoTransactions && (
        <WrapperNoTransactionsStyled>
          <img
            src={NoTransactionsIcon}
            alt={t('balancePage.recentTransactions.transactions.noTransactions')}
            width={isMobile ? 180 : 255}
            height={isMobile ? 180 : 200}
          />
          <Title style={{ textAlign: 'center' }} size={isMobile ? TITLE_SIZE.h4 : TITLE_SIZE.h3}>
            {t('balancePage.recentTransactions.transactions.noTransactions')}
          </Title>
        </WrapperNoTransactionsStyled>
      )}

      {!isNoTransactions && (
        <WrapperTabledTransactionsStyled>
          {!isMobile && (
            <TabletElementStyled style={{ padding: '10px' }}>
              <Text size={TEXT_SIZE.sub} weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.darkGrey}>
                {t('balancePage.recentTransactions.transactions.action')}
              </Text>
              <Text
                size={TEXT_SIZE.sub}
                weight={TEXT_WEIGHT.bold}
                style={{ whiteSpace: 'nowrap' }}
                colorText={COLOR_MAP.text.darkGrey}
              >
                {t('balancePage.recentTransactions.transactions.balanceAfter')}
              </Text>
              <Text
                size={TEXT_SIZE.sub}
                weight={TEXT_WEIGHT.bold}
                style={{ whiteSpace: 'nowrap' }}
                colorText={COLOR_MAP.text.darkGrey}
              >
                {t('balancePage.recentTransactions.transactions.dateAndTime')}
              </Text>
            </TabletElementStyled>
          )}

          {UserTransactionsCreditsIsLoading && <TransactionsSkeleton />}

          {!UserTransactionsCreditsIsLoading &&
            UserTransactionsCreditsData.entities.map((element, index) => {
              const { createdAt, volume, balanceAfter, description } = element;

              return (
                <TabletElementStyled key={index}>
                  <WrapperDescriptionsElementStyled>
                    <TransactionInformationStyled>
                      <ArrivalAndExpenseStyled>
                        {volume > BOUNDARY_DETERMINING_BALANCE ? <ArrowUpCircleIcon /> : <ArrowDownCircleIcon />}
                        <Text
                          weight={TEXT_WEIGHT.bold}
                          colorText={
                            volume > BOUNDARY_DETERMINING_BALANCE ? COLOR_MAP.accent.green : COLOR_MAP.accent.red
                          }
                          size={TEXT_SIZE.sub}
                        >
                          {volume}
                        </Text>
                      </ArrivalAndExpenseStyled>

                      <Text size={TEXT_SIZE.sub}>{description}</Text>
                    </TransactionInformationStyled>

                    {/* {invoiceUrl && (                               // отключил по просьбе Бориса, пока временно
                  <WrapperLinkElementStyled>
                    <LinkIcon />
                    <LinkClassic href={invoiceUrl}>
                      <Text colorText={COLOR_MAP.text.brandColor} size={TEXT_SIZE.sub}>
                        Invoice
                      </Text>
                    </LinkClassic>
                  </WrapperLinkElementStyled>
                )} */}
                  </WrapperDescriptionsElementStyled>

                  <WrapperBalanceAfterStyled>
                    {isMobile && (
                      <Text size={TEXT_SIZE.sub} style={{ whiteSpace: 'nowrap' }} colorText={COLOR_MAP.text.grey}>
                        {t('balancePage.recentTransactions.transactions.balanceAfter')}
                      </Text>
                    )}
                    <Text size={TEXT_SIZE.sub}>{balanceAfter}</Text>
                  </WrapperBalanceAfterStyled>

                  <WrapperDateAndTimeStyled>
                    {isMobile && (
                      <Text size={TEXT_SIZE.sub} style={{ whiteSpace: 'nowrap' }} colorText={COLOR_MAP.text.grey}>
                        {t('balancePage.recentTransactions.transactions.dateAndTime')}
                      </Text>
                    )}
                    <Text size={TEXT_SIZE.sub}>{dataFormatterViewFive(createdAt)}</Text>
                  </WrapperDateAndTimeStyled>
                </TabletElementStyled>
              );
            })}
        </WrapperTabledTransactionsStyled>
      )}

      {!UserTransactionsCreditsIsLoading && !recordLimitTransactions && !isNoTransactions && (
        <WrapperPaginationStyled>
          <Pagination
            pageNumber={pageNumber}
            itemCount={UserTransactionsCreditsData.itemCount}
            setPageNumber={setPageNumber}
            setLimit={setLimit}
            limit={limit}
          />
        </WrapperPaginationStyled>
      )}
    </TransactionsStyled>
  );
};
