import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { markedReadNotification } from '../../../../api/notifications';

import { useProductByIdQuery } from '../../../../api/product/hooks/useProductByIdQuery';
import { QUERY_KEY } from '../../../../common';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { BUTTON_SIZE } from '../../../../componentUI/simple/Button';
import { TEXT_SIZE, Text } from '../../../../componentUI/simple/Text';

import { useStyledModal } from '../../../../hooks';
import { NotificationType } from '../../../../redux/types';
import { UploadRequiredPhotos } from '../../../UploadRequiredPhotos';

import { MatchIcon } from '../../../../common/icons/SvgInline/SvgIcons_3';
import { RightArrow } from '../../../../common/icons/SvgInline/SvgIcons_7';

import { dataDotFormatterNotification } from '../../../../helpers/dataFormatter';

import { NotificationPageType } from '../../../../common/constants';

import {
  CheckIconWrapper,
  MessageInformationWrapper,
  NotificationMessageWrapper,
  TertiaryButtonStyled,
  stylesForButton,
} from './styled';

type Props = {
  changeShowNotification: () => void;
  product: NotificationType;
  currentTab: NotificationPageType;
  description: string;
  title: string;
};

const { notViewed } = NotificationPageType;

export const NotificationCard = ({ product, description, title, currentTab, changeShowNotification }: Props) => {
  const { id, productId, createdAt } = product;

  const client = useQueryClient();
  const navigate = useNavigate();

  const { data: dataProductById } = useProductByIdQuery(productId || '', Boolean(productId));

  const { mutate: notificationReadMarked } = useMutation({
    mutationFn: markedReadNotification,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [QUERY_KEY.getNotification] });
    },
  });

  const [Modal, toggleModal] = useStyledModal();

  const onClose = () => {
    notificationReadMarked(`?id=${id}`);
    toggleModal();
  };

  const problematicIndexesV2 = dataProductById?.angles.filter((angle) => Object.hasOwn(angle, 'reason'));

  return (
    <NotificationMessageWrapper>
      {problematicIndexesV2 && dataProductById && (
        <Modal onClose={toggleModal}>
          <UploadRequiredPhotos
            productId={dataProductById.id}
            problematicIndexesV2={problematicIndexesV2}
            onClose={onClose}
          />
        </Modal>
      )}
      {currentTab === notViewed && (
        <CheckIconWrapper>
          <MatchIcon color={COLOR_MAP.controls.clickableNormal} />
        </CheckIconWrapper>
      )}

      <MessageInformationWrapper>
        <Text size={TEXT_SIZE.annotation} colorText={COLOR_MAP.text.grey}>
          {dataDotFormatterNotification(createdAt)}
        </Text>
        <Text>{title}</Text>
        <Text size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.darkGrey}>
          {description}
        </Text>
      </MessageInformationWrapper>

      <TertiaryButtonStyled
        style={stylesForButton}
        onClick={() => {
          notificationReadMarked(`?id=${id}`);

          navigate(`/card/${productId}`, {
            state: { var: 'completed', page: productId },
          });

          changeShowNotification();
        }}
        size={BUTTON_SIZE.extraSmall}
      >
        View order
        <RightArrow color={COLOR_MAP.accent.gold} size="16" />
      </TertiaryButtonStyled>
    </NotificationMessageWrapper>
  );
};
