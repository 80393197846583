import styled, { CSSProperties } from 'styled-components';
import { COLOR_MAP } from '../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../componentUI/screen';
import { TEXT_WEIGHT } from '../../componentUI/simple/Text';

export const WrapperInstructionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PhotosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ButtonContainer = styled.div`
  padding: 40px 0 0 0;
  width: 100%;

  display: flex;
  justify-content: flex-end;

  border-top: 1px solid ${COLOR_MAP.accent.lightGrey};

  ${truthfulAdaptive.maxWidth.tabletNew} {
    padding: 16px 0 0 0;
    border-top: none;
  }
`;

export const stylesForButtons: CSSProperties = {
  textTransform: 'uppercase',
  fontWeight: TEXT_WEIGHT.bold,
};
