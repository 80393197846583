import React from 'react';
import { COLOR_MAP } from '../../componentUI/colorsMap';
import { Text, TEXT_SIZE } from '../../componentUI/simple/Text';
import { TermsBlockStyled } from './styled';
import { LinkRouter } from '../../componentUI/simple/Link';

export const TermsBlock = () => {
  const currentYear = new Date().getFullYear();

  return (
    <TermsBlockStyled>
      <Text size={TEXT_SIZE.annotation} colorText={COLOR_MAP.text.darkGrey}>
        {currentYear} © LegitGrails
      </Text>

      <LinkRouter size={TEXT_SIZE.annotation} to="/conditions">
        Terms & Conditions
      </LinkRouter>
    </TermsBlockStyled>
  );
};
