/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import Select, { SelectInstance } from 'react-select';
import { DropdownIndicator } from '../../../common';
import { ModelImageIcon } from '../../../common/icons/SvgInline/SvgIcons_4';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { TEXT_SIZE, TEXT_WEIGHT, Text } from '../../../componentUI/simple/Text';
import { useMatchMedia } from '../../../hooks';
import { CustomMenuList, CustomOption, CustomSingleValue } from '../CustomSelectorItemsWithImages';
import { SelectedModelsType } from '../types';
import { SelectWithTitleWrapper, SelectWrapper, StyledOptionWrapper, stylesForMenu, stylesForOption } from './styled';
import { Props } from './types';

/** Выпадающий список с названиями и миниатюрами моделей */

// требования бизнеса, показывать поле поиска при открытии выпадающего списка,
// т.е. не показывать курсор в поле выпадающего списка при его фокусе
// продолжение логики в компоненте CustomSingleValue
// стилизация input для этого же
const CURSOR_OFF = { key: '', value: '', label: '', name: '', jpegUrl: 'offCursor' };

export const ModelSelector = ({ optionsModels, setSelectedModelName, setModelNameSelect, modelNameSelect }: Props) => {
  const { isMobile } = useMatchMedia();

  const selectRef = useRef<SelectInstance<SelectedModelsType> | null>(null);

  const isDisabledSelect = Boolean(!optionsModels);

  const [isSearch, setIsSearch] = useState(true);

  useEffect(() => {
    if (isSearch) {
      if (selectRef.current) {
        selectRef.current.focus();
      }
    }
  }, [isSearch]);

  return (
    <SelectWithTitleWrapper>
      <Text size={TEXT_SIZE.sub} weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.darkGrey}>
        Model name*
      </Text>

      <SelectWrapper>
        <Select
          styles={{
            option: (base) => ({ ...base, ...stylesForOption }),
            menu: (base) => ({ ...base, ...stylesForMenu }),
            input: (base) => ({ ...base, color: modelNameSelect?.jpegUrl === 'offCursor' ? 'none' : 'transparent' }),
          }}
          value={modelNameSelect}
          components={{
            DropdownIndicator,
            Option: CustomOption,
            SingleValue: CustomSingleValue,
            MenuList: CustomMenuList,
          }}
          options={optionsModels || undefined}
          placeholder={
            <StyledOptionWrapper>
              <ModelImageIcon size={isMobile ? '44' : '80'} />
              <span>Select or start typing to search...</span>
            </StyledOptionWrapper>
          }
          onChange={(event) => {
            setSelectedModelName(event?.name || null);
            setModelNameSelect(event);
          }}
          isDisabled={isDisabledSelect}
          blurInputOnSelect
          closeMenuOnSelect
          onMenuClose={() => {
            setIsSearch(true);
          }}
          onMenuOpen={() => {
            setModelNameSelect(CURSOR_OFF);
            setIsSearch(true);
          }}
          closeMenuOnScroll={() => {
            setIsSearch(false);
            return false;
          }}
          isSearchable={isSearch}
        />
      </SelectWrapper>
    </SelectWithTitleWrapper>
  );
};
