/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ResultStatusName } from '../../../../../api/product/type';
import { Shield, TimerIcon } from '../../../../../common/icons/SvgInline/SvgIcons_8';
import { COLOR_MAP } from '../../../../../componentUI/colorsMap';
import { Checkbox } from '../../../../../componentUI/simple/Checkbox';
import { Text, TEXT_SIZE } from '../../../../../componentUI/simple/Text';
import { NotificationType } from '../../../../../componentUI/types';
import {
  dataFormatterFromTable,
  getActionInfo,
  getImageOrder,
  getNumberOrder,
  getOutcomeInfo,
  getStatusProductInfo,
} from '../../../../../helpers';
import { addToast } from '../../../../../redux/reducers/toast-reducer';
import { changeStatusCheckbox } from '../../../helpers';
import {
  AnswerTitleStyled,
  BrandTitleStyled,
  CheckboxTitleStyled,
  DateTitleStyled,
  ElementTablePartIsMobileStyled,
  FinGuardLabel,
  FooterStyled,
  ImgTitleStyled,
  MainStyled,
  ModelTitleStyled,
  OrderInfoStyled,
  OrderNumberTitleStyled,
  OutcomeTitleStyled,
  ProductInformationUnitOneStyled,
  ProductInformationUnitTwoStyled,
  StatusTitleStyled,
  TitleStyled,
  WrapperCategoryStyled,
  WrapperOrderAnswerStyled,
} from './styled';
import { Props } from './types';

// isPreFilledOrder - если заказ используется как шаблон для нового пользователя
// поле orderDate не показываем
const { DRAFT } = ResultStatusName;

export const ElementTablePartIsMobile = ({
  order,
  openOrderCard,
  DeleteDraftToggleModal,
  setIdProduct,
  isPreFilledOrder,
  setAllChecksDraftsElements,
  allChecksDraftsElements,
  tableColumn,
  numberOrdersUser,
}: Props) => {
  const dispatch = useDispatch();

  // в мобильном представлении карточки заказа, в зависимости от выбранной страницы
  // изменения касаются только полей draft, outcome, status
  const { draft, outcome, status } = tableColumn;

  const isDraft = order.systemStatus === DRAFT;

  const [isCopyTextBuffer, getIsCopyTextBuffer] = useState(false);

  // добавляем новое состояние чекбокса Draft в стейт setAllChecksDraftsElements
  // при создании записи заказа
  useEffect(() => {
    setAllChecksDraftsElements((prev) => {
      return { ...prev, [order.id]: false };
    });
  }, []);

  useEffect(() => {
    if (isCopyTextBuffer) {
      dispatch(
        addToast({
          text: 'Order number copied.',
          type: NotificationType.Success,
        }),
      );

      getIsCopyTextBuffer(false);
    }
  }, [isCopyTextBuffer]);

  const orderImg = getImageOrder(order.photoUrl, true);
  const orderNumber = getNumberOrder(order.orderNumber, true, getIsCopyTextBuffer);
  const orderStatus = getStatusProductInfo(order.status, false, true);
  const orderDate = isPreFilledOrder ? null : dataFormatterFromTable(order.createdAt);
  const orderBrand = order.brand;
  const orderModel = order.modelName;
  const orderCategory = order.productType;
  const orderAnswer = order.answerTime;
  const orderOutcome = getOutcomeInfo(order.checkStatus, true, true, isDraft);
  const orderAction = getActionInfo({
    setIdProduct,
    OpenDeletePhotoModal: DeleteDraftToggleModal,
    certificateAvailable: order.certificateAvailable,
    systemStatus: order.systemStatus,
    checkStatus: order.checkStatus,
    publicId: order.publicId,
    isBigSize: true,
    id: order.id,
    isFinGuardActive: order.isFinancialGuaranteeActive,
  });

  return (
    <ElementTablePartIsMobileStyled
      isFinGuardActive={order.isFinancialGuaranteeActive}
      numberOrdersUser={numberOrdersUser}
      onClick={() => {
        openOrderCard(isDraft, order.id);
      }}
    >
      {order.isFinancialGuaranteeActive && (
        <FinGuardLabel>
          <Shield size="14" color={COLOR_MAP.accent.white} />
          <Text size={TEXT_SIZE.button} colorText={COLOR_MAP.text.white}>
            Financial Guarantee
          </Text>
        </FinGuardLabel>
      )}
      <TitleStyled>
        {status && <StatusTitleStyled>{orderStatus}</StatusTitleStyled>}

        {outcome && <OutcomeTitleStyled> {orderOutcome}</OutcomeTitleStyled>}

        {draft && (
          <CheckboxTitleStyled onClick={(event) => event.stopPropagation()}>
            <Checkbox
              onChange={() => {
                changeStatusCheckbox(setAllChecksDraftsElements, order.id);
              }}
              checked={(allChecksDraftsElements && allChecksDraftsElements[order.id]) || false}
            />
          </CheckboxTitleStyled>
        )}
      </TitleStyled>
      <MainStyled>
        <ProductInformationUnitOneStyled>
          <OrderInfoStyled>
            {orderNumber && <OrderNumberTitleStyled>{orderNumber}</OrderNumberTitleStyled>}

            {orderDate && (
              <DateTitleStyled>
                <Text size={TEXT_SIZE.regular}>{orderDate}</Text>
              </DateTitleStyled>
            )}

            {orderAnswer && (
              <AnswerTitleStyled>
                <WrapperOrderAnswerStyled>
                  <TimerIcon />
                  <Text size={TEXT_SIZE.regular}>{orderAnswer}</Text>
                </WrapperOrderAnswerStyled>
              </AnswerTitleStyled>
            )}
          </OrderInfoStyled>

          <ImgTitleStyled>{orderImg}</ImgTitleStyled>
        </ProductInformationUnitOneStyled>

        <ProductInformationUnitTwoStyled>
          {orderBrand && (
            <BrandTitleStyled>
              <Text size={TEXT_SIZE.regular}>{orderBrand}</Text>
            </BrandTitleStyled>
          )}

          {orderModel && (
            <ModelTitleStyled>
              <Text size={TEXT_SIZE.regular}>{orderModel}</Text>
            </ModelTitleStyled>
          )}

          {orderCategory && (
            <WrapperCategoryStyled>
              <Text size={TEXT_SIZE.regular}>{orderCategory}</Text>
            </WrapperCategoryStyled>
          )}
        </ProductInformationUnitTwoStyled>
      </MainStyled>

      {orderAction && <FooterStyled>{orderAction}</FooterStyled>}
    </ElementTablePartIsMobileStyled>
  );
};
