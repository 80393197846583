import { ResultStatusId, SortProduct } from '../../../api/product/type';
import { AuthPageType } from '../../../common/constants';
import { SelectedSetFiltersType } from '../containers/AuthenticationsTablet/types';
import { convertingObjectQuery } from './convertingObjectQuery';

type InputDataType = {
  selectWebsitePage: string | undefined;
  limitTablet: number;
  selectPageAutTablet: number;
  sortTablet: SortProduct | null;
  searchTablet: string | null;
  selectedFilters: SelectedSetFiltersType;
};

// обозначение страниц на которых встречается элемент AuthenticationsTablet
const { all, photosNeeded, underReview, completed, drafts, dashboard } = AuthPageType;

const { createdAtUp } = SortProduct;

const {
  OPEN_ID,
  UPDATED_ID,
  ERROR_ID,
  UPDATE_NEEDED_ID,
  READY_ID,
  COMPLETED_ID,
  AWAIT_CERTIFICATE_ID,
  DRAFT_ID,
  DOUBLE_CHECK_ID,
} = ResultStatusId;

// selectWebsitePage - страница на которой находится блок AuthenticationsTablet
// selectPageAutTablet - страница которую выбрал пользователь в блоке Pagination
// sortTablet - тип сортировки столбца

const allPage = [
  { id: OPEN_ID },
  { id: UPDATED_ID },
  { id: ERROR_ID },
  { id: UPDATE_NEEDED_ID },
  { id: READY_ID },
  { id: COMPLETED_ID },
  { id: AWAIT_CERTIFICATE_ID },
  { id: DRAFT_ID },
  { id: DOUBLE_CHECK_ID },
];
const photosNeededPage = [{ id: UPDATE_NEEDED_ID }];
const underReviewPage = [{ id: OPEN_ID }, { id: UPDATED_ID }, { id: ERROR_ID }, { id: DOUBLE_CHECK_ID }];
const completedPage = [{ id: READY_ID }, { id: COMPLETED_ID }, { id: AWAIT_CERTIFICATE_ID }];
const draftsPage = [{ id: DRAFT_ID }];
const dashboardPage = [
  { id: OPEN_ID },
  { id: UPDATED_ID },
  { id: ERROR_ID },
  { id: UPDATE_NEEDED_ID },
  { id: READY_ID },
  { id: COMPLETED_ID },
  { id: AWAIT_CERTIFICATE_ID },
  { id: DRAFT_ID },
  { id: DOUBLE_CHECK_ID },
];

/** задает параметры запроса данных
 * для эндпоинта 'products/get-products' в зависимости
 * от того на какой странице сайта вызван модуль AuthenticationsTablet
 * и какие настройки сортировки, фильтров, пагинации выбраны */

export const selectionPageSettings = ({
  selectWebsitePage,
  limitTablet,
  selectPageAutTablet,
  sortTablet,
  searchTablet,
  selectedFilters,
}: InputDataType) => {
  const optionsFalter = convertingObjectQuery(selectedFilters);

  switch (selectWebsitePage) {
    case all:
      return {
        page: selectPageAutTablet,
        limit: limitTablet,
        sort: sortTablet || createdAtUp,
        resultStatuses: allPage || undefined,
        search: searchTablet === '' ? null : searchTablet,
        ...optionsFalter,
      };
    case photosNeeded:
      return {
        page: selectPageAutTablet,
        limit: limitTablet,
        sort: sortTablet || createdAtUp,
        resultStatuses: photosNeededPage || undefined,
        search: searchTablet === '' ? null : searchTablet,
        ...optionsFalter,
      };
    case underReview:
      return {
        page: selectPageAutTablet,
        limit: limitTablet,
        sort: sortTablet || createdAtUp,
        resultStatuses: underReviewPage || undefined,
        search: searchTablet === '' ? null : searchTablet,
        ...optionsFalter,
      };
    case completed:
      return {
        page: selectPageAutTablet,
        limit: limitTablet,
        sort: sortTablet || createdAtUp,
        resultStatuses: completedPage || undefined,
        search: searchTablet === '' ? null : searchTablet,
        ...optionsFalter,
      };
    case drafts:
      return {
        page: selectPageAutTablet,
        limit: limitTablet,
        sort: sortTablet || createdAtUp,
        resultStatuses: draftsPage || undefined,
        search: searchTablet === '' ? null : searchTablet,
        ...optionsFalter,
      };
    case dashboard:
      return {
        page: 1,
        limit: 10,
        sort: createdAtUp,
        resultStatuses: dashboardPage || undefined,
        search: null,
      };
    default:
      return {
        page: selectPageAutTablet,
        limit: limitTablet,
        sort: sortTablet || createdAtUp,
        resultStatuses: allPage || undefined,
        search: searchTablet === '' ? null : searchTablet,
      };
  }
};
