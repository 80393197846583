/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useUploadRequestPhotoMutate } from '../../api/product/hooks/useUploadRequestPhotoMutate';
import { LegitIcon } from '../../common/icons/SvgInline/SvgIcons_1';
import { CameraIcon } from '../../common/icons/SvgInline/SvgIcons_4';
import { UploadingPhotoOverlay } from '../../componentUI/complex/UploadingPhotoOverlay/UploadingPhotoOverlay';
import { TAG_NAME } from '../../componentUI/constants';
import { Button, LinkSecondButtonShowHide } from '../../componentUI/simple/Button';
import { Title, TITLE_SIZE } from '../../componentUI/simple/Title';
import { NotificationType } from '../../componentUI/types';
import { useMatchMedia } from '../../hooks';
import { addToast } from '../../redux/reducers/toast-reducer';
import { PhotoInstructions } from '../PhotoInstructions';
import { IndexRequiredPhoto } from './components';
import { checkUploadedPhotosIndex } from './helpers';
import { locale } from './locale';
import { ButtonContainer, PhotosContainer, stylesForButtons, WrapperInstructionsBlock } from './styled';
import { AllUploadedPhotosIndexType, Props, UploadedPhotosType } from './types';

const { iconText, showButtonText, textTitlePhotoInstructions, linkToPhotoRules } = locale;

/**
 * модалка для замены и/или отправки новых фоток
 *
 * Modal for replace and/or new photos send
 */

export const UploadRequiredPhotos = ({ problematicIndexesV2, productId, onClose, onRefetch }: Props) => {
  const dispatch = useDispatch();
  const { isDesktopSNew } = useMatchMedia();

  // содержит массив загруженных на бэк фотографий, ВСЕГО заказа
  const [allUploadedPhotos, setAllUploadedPhotos] = useState<Array<UploadedPhotosType>>([]);

  // содержит информацию о том загружена хотя бы одна фотография в каждый доступный индекс
  const [allUploadedPhotosIndex, setAllUploadedPhotosIndex] = useState<AllUploadedPhotosIndexType | null>(null);

  const isAllUploadedPhotosIndex = checkUploadedPhotosIndex({
    object: allUploadedPhotosIndex,
  });

  const isDisabledBtnConfirmAndSend = !isAllUploadedPhotosIndex;

  const {
    mutate: updatePhotosIndex,
    isSuccess: isSuccessUpdatePhotosIndex,
    isLoading: isLoadingUpdatePhotosIndex,
  } = useUploadRequestPhotoMutate();

  useEffect(() => {
    if (isSuccessUpdatePhotosIndex) {
      if (onRefetch) {
        onRefetch();
      }

      onClose();

      dispatch(
        addToast({
          text: 'Photos uploaded and sent successfully.',
          type: NotificationType.Success,
        }),
      );
    }
  }, [isSuccessUpdatePhotosIndex]);

  return (
    <>
      <Title tag={TAG_NAME.h3} size={TITLE_SIZE.h3}>
        Uploaded photos
      </Title>

      <WrapperInstructionsBlock>
        <LinkSecondButtonShowHide
          iconText={iconText}
          text={showButtonText}
          href={linkToPhotoRules}
          target="_blank"
          rel="noreferrer"
        >
          <CameraIcon />
        </LinkSecondButtonShowHide>

        <PhotoInstructions textTitle={textTitlePhotoInstructions} isSelect />
      </WrapperInstructionsBlock>

      <PhotosContainer>
        {problematicIndexesV2.map((problematicIndex) => {
          return (
            <IndexRequiredPhoto
              key={problematicIndex.id}
              problematicIndex={problematicIndex}
              setAllUploadedPhotos={setAllUploadedPhotos}
              productId={productId}
              setAllUploadedPhotosIndex={setAllUploadedPhotosIndex}
            />
          );
        })}
      </PhotosContainer>

      <ButtonContainer>
        <Button
          isStretch={!!isDesktopSNew}
          style={stylesForButtons}
          disabled={isDisabledBtnConfirmAndSend || isLoadingUpdatePhotosIndex}
          onClick={() => {
            updatePhotosIndex({
              productId,
              photos: allUploadedPhotos,
            });
          }}
        >
          <LegitIcon />
          Confirm and send
        </Button>

        {isLoadingUpdatePhotosIndex && <UploadingPhotoOverlay />}
      </ButtonContainer>
    </>
  );
};
