import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-tabs-scrollable';
import 'react-tabs-scrollable/dist/rts.css';
import { TabItemLeftArrowIcon, TabItemRightArrowIcon } from '../../common/icons/SvgInline/SvgIcons_12';
import { useMatchMedia } from '../../hooks';
import { PagesType } from '../../modules/AuthenticationCardV3/containers/types';
import { TypeTitle } from './styled';
import './styledForWithoutNNavigate.css';

interface TabData {
  name: PagesType;
  titleText: string;
}

interface TabsScrollableProps {
  tabs: TabData[];
  activeTabName: string;
  onTabChange: (tabName: PagesType) => void;
}

export const TabsScrollableWithoutNavigate = ({ tabs, activeTabName, onTabChange }: TabsScrollableProps) => {
  const [isStartingPosition, setIsStartingPosition] = React.useState(true);
  const [isEndingPosition, setIsEndPosition] = React.useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const { isDesktopS } = useMatchMedia();

  const onTabClick = (e: BaseSyntheticEvent, index: number) => {
    setActiveTab(index);
    onTabChange(tabs[index].name);
  };

  useEffect(() => {
    if (isDesktopS) {
      setActiveTab(1);
      onTabChange(tabs[0].name);
    } else {
      setActiveTab(1);
      onTabChange(tabs[0].name);
    }
  }, [isDesktopS]);

  return (
    <Tabs
      mode="scrollSelectedToCenterFromView"
      leftBtnIcon={<TabItemLeftArrowIcon />}
      rightBtnIcon={<TabItemRightArrowIcon />}
      navBtnClassName="navBtnStyles"
      leftNavBtnClassName={isStartingPosition ? 'navBtnOff leftNavBtnClassStyles ' : 'leftNavBtnClassStyles'}
      rightNavBtnClassName={isEndingPosition ? 'navBtnOff rightNavBtnClassStyles' : 'rightNavBtnClassStyles'}
      tabsUpperContainerClassName="tabsUpperContainerStyles"
      tabsContainerClassName="navBtnStyles"
      activeTab={activeTab}
      onTabClick={onTabClick}
      hideNavBtnsOnMobile={false}
      tabsScrollAmount={3}
      didReachStart={(val: boolean) => setIsStartingPosition(val)}
      didReachEnd={(val: boolean) => setIsEndPosition(val)}
    >
      {tabs.map(({ name, titleText }) => (
        <Tab key={name} className="tabsContainerStyles">
          <TypeTitle isChosen={name === activeTabName} onClick={() => onTabChange(name)}>
            {titleText}
          </TypeTitle>
        </Tab>
      ))}
    </Tabs>
  );
};
