import React, { useState } from 'react';
import Select from 'react-select';

import { DropdownIndicator } from '../../../../../../common';
import { TrashIcon } from '../../../../../../common/icons/SvgInline/SvgIcons_2';

import { FilterSelectTypeOne, FilterSelectTypeTwo } from './components';
import { DateFilter } from './components/DateFilter';
import { formingDropDownList, getOptionsMasterSelect } from './helpers';
import { DependentFiltersStyled, FilterV2Styled, MasterFilterStyled, stylesForControl, TrashButton } from './styled';
import { NAME_OBJECT_GET_PRODUCT, OptionsMasterDropdownListType, Props } from './types';

const { publicId, date, brand, modelName, sku, checkStatus } = NAME_OBJECT_GET_PRODUCT;

/** содержит блок с выбранным пользователем фильтром для таблицы */

export const FilterV2 = ({ filterID, removeFilter, setSelectedSetFilters, selectedSetFilters }: Props) => {
  const [currentValueDropDownList, setCurrentValueDropDownList] = useState<OptionsMasterDropdownListType | null>(null);

  const optionsMasterDropdownList = getOptionsMasterSelect(selectedSetFilters);

  const selectDropDownList = formingDropDownList({ masterFilter: currentValueDropDownList?.value, filterID });

  const isIdFilter = selectDropDownList?.nameObject === publicId;
  const isDateFilter = selectDropDownList?.nameObject === date;
  const isBrandFilter = selectDropDownList?.nameObject === brand;
  const isModelNameFilter = selectDropDownList?.nameObject === modelName;
  const isSkuFilter = selectDropDownList?.nameObject === sku;
  const isCheckStatusFilter = selectDropDownList?.nameObject === checkStatus;

  const isFilterSelectTypeOne = isIdFilter || isModelNameFilter || isSkuFilter;
  const isFilterSelectTypeTwo = isBrandFilter || isCheckStatusFilter;

  return (
    <FilterV2Styled isShow={isFilterSelectTypeOne || isFilterSelectTypeTwo || isDateFilter}>
      <MasterFilterStyled>
        <Select
          styles={{
            control: (base) => ({ ...base, ...stylesForControl }),
          }}
          components={{ DropdownIndicator }}
          options={optionsMasterDropdownList}
          classNamePrefix="custom-select"
          value={currentValueDropDownList}
          onChange={(option) => {
            if (option) {
              setCurrentValueDropDownList(option);
            }
          }}
          isSearchable={false}
        />
      </MasterFilterStyled>

      <DependentFiltersStyled>
        {isFilterSelectTypeOne && (
          <FilterSelectTypeOne setSelectedSetFilters={setSelectedSetFilters} selectDropDownList={selectDropDownList} />
        )}

        {isFilterSelectTypeTwo && (
          <FilterSelectTypeTwo setSelectedSetFilters={setSelectedSetFilters} selectDropDownList={selectDropDownList} />
        )}

        {isDateFilter && (
          <DateFilter setSelectedSetFilters={setSelectedSetFilters} selectDropDownList={selectDropDownList} />
        )}
      </DependentFiltersStyled>

      <TrashButton
        onClick={() => {
          removeFilter(filterID);
        }}
      >
        <TrashIcon />
      </TrashButton>
    </FilterV2Styled>
  );
};
