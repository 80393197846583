/* eslint-disable max-len */
import styled from 'styled-components';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../../componentUI/screen';

// https://utopia.fyi/clamp/calculator -вычисление clamp

const PhotoCardV2Styled = styled.section<{ drag: boolean }>`
  position: relative;

  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  border-radius: 10px;

  /* Fallback for older browsers */
  border: 1px dashed ${COLOR_MAP.accent.grey};

  /* Modern browsers */
  @supports (background-clip: content-box) {
    border: none;

    background-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='${encodeURIComponent(
      COLOR_MAP.accent.grey,
    )}' stroke-width='1' stroke-dasharray='6, 6' stroke-dashoffset='0' stroke-linecap='square'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 99.99% 99.99%;
  }

  & > * {
    pointer-events: ${({ drag }) => (drag ? 'none' : 'auto')};
  }

  ${truthfulAdaptive.maxWidth.tabletNew} {
    padding: 16px 12px;
    gap: 16px;
  }
`;

const DragAndDropIndicatorStyled = styled.div`
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  pointer-events: none;

  background: ${COLOR_MAP.background.brandColorSnow};
`;

const WrapperTitleDragAndDropStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const HeaderStyled = styled.header``;

const MainStyled = styled.main`
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  border-radius: 10px;
  background: ${COLOR_MAP.background.greyShade2};

  ${truthfulAdaptive.maxWidth.tabletNew} {
    padding: 16px 12px;
    gap: 16px;
  }
`;

const RequiredChangesStyled = styled.section`
  display: grid;
  grid-template-columns: 198px 1fr;
  gap: 24px;

  ${truthfulAdaptive.maxWidth.tabletNew} {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;

const WrapperTitleRequiredStyled = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
  justify-items: start;
  gap: 8px;
`;

const ReasonsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ReasonsDescriptionsStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px 14px;
`;

const WrapperTextReasons = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ReasonsPhotosStyled = styled.div`
  width: 102px;

  cursor: pointer;

  ${truthfulAdaptive.maxWidth.tabletNew} {
    width: 128px;
  }

  ${truthfulAdaptive.maxWidth.mobile} {
    width: clamp(5rem, -1.5714rem + 32.8571vw, 10.75rem);
  }
`;

const CommentStyled = styled.section`
  display: grid;
  grid-template-columns: 198px 1fr;
  gap: 24px;

  ${truthfulAdaptive.maxWidth.tabletNew} {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;

const CommentDescriptionsStyled = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: ${COLOR_MAP.background.white};

  word-break: break-all;
`;

const UploadPhotosStyled = styled.section`
  display: grid;
  // 198px основной контейнер по макету + 16px - паддинг которого нет в этом блоке
  // но есть в блоке выше
  grid-template-columns: 214px 1fr;
  grid-template-areas:
    'Wrapper Image'
    'Wrapper Image';

  gap: 12px 24px;

  ${truthfulAdaptive.maxWidth.tabletNew} {
    grid-template-columns: 100%;
    grid-template-areas:
      'Title'
      'Image'
      'Button';

    gap: 12px;
  }
`;

const WrapperUploadPhotosInfoStyled = styled.div`
  grid-area: Wrapper;

  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const WrapperTitlePhotosStyled = styled.div`
  grid-area: Title;

  display: flex;
`;

const WrapperLickStyled = styled.div`
  grid-area: Button;

  display: flex;
  align-items: center;
  gap: 5px;
`;

const ContainerUploadPhotosStyled = styled.div`
  grid-area: Image;

  display: flex;
  flex-wrap: wrap;

  gap: 16px;

  ${truthfulAdaptive.maxWidth.tabletNew} {
    gap: 12px;
  }
`;

const WrapperSecondButton = styled.div<{ isDisabledBtnUploadPhoto: boolean }>`
  width: 102px;
  height: 102px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  background-color: ${COLOR_MAP.background.brandColorSoftV4};

  background-color: ${({ isDisabledBtnUploadPhoto }) => isDisabledBtnUploadPhoto && COLOR_MAP.background.lightGrey};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${({ isDisabledBtnUploadPhoto }) =>
        !isDisabledBtnUploadPhoto && COLOR_MAP.background.brandColorSnow};
    }
  }

  &:active {
    background-color: ${({ isDisabledBtnUploadPhoto }) =>
      !isDisabledBtnUploadPhoto && COLOR_MAP.background.separatorColorOne};
  }

  ${truthfulAdaptive.maxWidth.tabletNew} {
    width: 128px;
    height: 128px;
  }

  ${truthfulAdaptive.maxWidth.mobile} {
    width: clamp(5rem, -1.5714rem + 32.8571vw, 10.75rem);
    height: clamp(5rem, -1.5714rem + 32.8571vw, 10.75rem);
  }
`;

const FooterStyled = styled.footer``;

export {
  CommentDescriptionsStyled,
  CommentStyled,
  ContainerUploadPhotosStyled,
  DragAndDropIndicatorStyled,
  FooterStyled,
  HeaderStyled,
  MainStyled,
  PhotoCardV2Styled,
  ReasonsDescriptionsStyled,
  ReasonsPhotosStyled,
  ReasonsStyled,
  RequiredChangesStyled,
  UploadPhotosStyled,
  WrapperLickStyled,
  WrapperSecondButton,
  WrapperTextReasons,
  WrapperTitleDragAndDropStyled,
  WrapperTitlePhotosStyled,
  WrapperTitleRequiredStyled,
  WrapperUploadPhotosInfoStyled,
};
