/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLoginMutate } from '../../../../api/auth/hooks';
import { QUERY_KEY } from '../../../../common';
import { useFormField, useIsMobile } from '../../../../hooks';
import { Button, SecondButton } from '../../../../componentUI/simple/Button';
import { ToggleSwitch } from '../../../../componentUI/simple/ToggleSwitch';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { TAG_NAME } from '../../../../componentUI/constants';
import { LinkRouter } from '../../../../componentUI/simple/Link';
import { Text } from '../../../../componentUI/simple/Text';
import { useGoogleAnalytics } from '../../../../analytics';
import { InputWithError } from '../../../../componentUI/complex/InputWithError';
import { Loader, TermsBlock } from '../../../../components';
import { validateEmail } from '../../../../helpers/validate';
import { AuthContainer } from '../../Auth';
import { GoogleLogo, LockIcon, MailIcon } from '../../assets';
import { ScreenResetPassRequestSuccess } from '../../components/InformationScreens';
import { LogoBlock, TitleBlock } from '../../components';
import { usePasswordIconManage } from '../../hooks/usePasswordIconManage';
import {
  LeftColumnStyled,
  RightColumnStyled,
  SeparatorStyled,
  WrapperInputStyled,
  WrapperPasswordStyled,
  WrapperSeparatorStyled,
  WrapperSignInStyled,
  WrapperSignUpStyled,
  WrapperTextStyled,
  WrapperToggleStyled,
} from './styled';
import { parseSearchParamToString } from '../../helpers/parseSearchParamToString';
import { NotificationType } from '../../../../componentUI/types';
import { addToast } from '../../../../redux/reducers/toast-reducer';
import { generateGoogleAuthUrl } from '../../helpers/generateGoogleAuthUrl';

const MINIMUM_NUMBER_CHARACTERS = 8;

export const SignIn = () => {
  useGoogleAnalytics();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const client = useQueryClient();

  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const googleError = searchParams.get('error');
  const googleErrorText = googleError
    ? parseSearchParamToString(googleError)
    : 'Something went wrong. Please try again.';

  const {
    mutate: login,
    data: loginData,
    isSuccess: isSuccessLoginData,
    isLoading: isLoadingLoginData,
  } = useLoginMutate();

  const [email, setEmail, emailError, setEmailError] = useFormField({ initValue: '', withError: true });
  const [password, setPassword, passwordError, setPasswordError] = useFormField({ initValue: '', withError: true });
  const { rightIconPassword, passTypeInput } = usePasswordIconManage(password);

  const [rememberMe, setRememberMe] = useState(false);

  // категория пользователей с Shopify
  const [isShopifyUser, setIsShopifyUser] = useState(false);

  const changeEmail = (email: string) => {
    if (!validateEmail(email) || email.length === 0) {
      setEmailError('Invalid email format');
      setEmail(email);
    } else {
      setEmailError('');
      setEmail(email);
    }
  };

  const changePassword = (password: string) => {
    if (password.length < MINIMUM_NUMBER_CHARACTERS || password.length === 0) {
      setPasswordError('Please fill in your password');
      setPassword(password);
    } else {
      setPasswordError('');
      setPassword(password);
    }
  };

  useEffect(() => {
    // проверка на то что юзер не из категории Shopify
    if (loginData && loginData.result) {
      // сохраняем валидный токен
      Cookies.set('jwt', loginData.token);
      Cookies.set('jwt-refresh', loginData.refreshToken);
      // перезапрашиваем список доступных url
      client.invalidateQueries({ queryKey: [QUERY_KEY.registerUser] });
      client.invalidateQueries({ queryKey: [QUERY_KEY.getAccountInfo] });

      navigate('/dashboard');
    }

    // пользователь с Shopify
    if (loginData && !loginData.result && !isShopifyUser) {
      setIsShopifyUser(true);
    }
  }, [isSuccessLoginData]);

  useEffect(() => {
    if (googleError) {
      dispatch(
        addToast({
          text: googleErrorText,
          type: NotificationType.Default,
        }),
      );
    }
  });

  const isDisableSingInBtn = Boolean(passwordError || emailError || password.length === 0 || email.length === 0);

  if (isLoadingLoginData) {
    return <Loader style={{ margin: '45vh auto' }} />;
  }

  return (
    <AuthContainer>
      <RightColumnStyled>
        <LogoBlock />

        {isShopifyUser && <ScreenResetPassRequestSuccess setIsShopifyUser={setIsShopifyUser} />}

        {!isShopifyUser && (
          <WrapperSignInStyled>
            <TitleBlock titleText="Welcome back!" subtitleText="Please login to continue" />

            <WrapperInputStyled>
              <InputWithError
                value={email}
                onChange={(event) => {
                  changeEmail(event);
                }}
                onBlur={(event) => {
                  changeEmail(event.currentTarget.value);
                }}
                placeholder="Email"
                errorMessage={emailError}
                type="email"
                leftIcon={<MailIcon fill={email ? COLOR_MAP.controls.normal : COLOR_MAP.controls.placeholder} />}
              />

              <InputWithError
                value={password}
                onChange={(event) => {
                  changePassword(event);
                }}
                onBlur={(event) => {
                  changePassword(event.currentTarget.value);
                }}
                placeholder="Password"
                errorMessage={passwordError}
                type={passTypeInput}
                leftIcon={<LockIcon fill={password ? COLOR_MAP.controls.normal : COLOR_MAP.controls.placeholder} />}
                rightIcon={rightIconPassword}
              />

              <WrapperPasswordStyled>
                <WrapperToggleStyled>
                  <ToggleSwitch
                    id="toggle"
                    size="xs"
                    value={rememberMe}
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <Text colorText={COLOR_MAP.text.white}>Remember me</Text>
                </WrapperToggleStyled>

                <LinkRouter to="/forgot-password">Forgot password?</LinkRouter>
              </WrapperPasswordStyled>

              <Button
                isStretch
                onClick={() =>
                  login({
                    email,
                    password,
                    rememberMe,
                  })
                }
                disabled={isDisableSingInBtn}
              >
                SIGN IN
              </Button>
            </WrapperInputStyled>

            <WrapperSeparatorStyled>
              <SeparatorStyled />
              <Text colorText={COLOR_MAP.text.white}>or</Text>
              <SeparatorStyled />
            </WrapperSeparatorStyled>

            <SecondButton style={{ gap: '10px' }} isStretch linkButton={generateGoogleAuthUrl('signin')}>
              <img src={GoogleLogo} width={24} height={24} alt="google logo" />
              SIGN IN WITH GOOGLE
            </SecondButton>

            <WrapperSignUpStyled>
              <Text tag={TAG_NAME.span} colorText={COLOR_MAP.text.white}>
                Don&apos;t have an account yet?
              </Text>

              <WrapperTextStyled>
                <Text tag={TAG_NAME.span} colorText={COLOR_MAP.text.white}>
                  Click here to&nbsp;
                </Text>

                <LinkRouter to="/signup">Sign Up</LinkRouter>
              </WrapperTextStyled>
            </WrapperSignUpStyled>
          </WrapperSignInStyled>
        )}

        <TermsBlock />
      </RightColumnStyled>

      {!isMobile && <LeftColumnStyled />}
    </AuthContainer>
  );
};
